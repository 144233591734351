import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { FilterContext } from '../../../contexts/filterContext';
import Checkbox from '../../../molecules/Checkbox/Checkbox';
import { breakpoints } from '../../../styles/atoms/breakpoints';
import Icon from '../../../styles/atoms/icons';
import { decamelize } from '../../../utils/helperFunctions';
import {
  getParams,
  updateParamsSilently,
} from '../../../utils/url/handleParams';
import { optionNameOverride } from '../utils/optionNameOverride';
import { Category, FilterStyles, OptionsContainer } from './Filter.styles';

const Filter = () => {
  const { filterDispatch, filterState } = useContext(FilterContext);
  const [accordionOpen, setAccordionOpen] = useState();
  const isLargeScreen = useMediaQuery(`(min-width: ${breakpoints.lg})`);
  const stableDispatch = useCallback(filterDispatch, []);

  const handleAccordionClick = categoryType => {
    accordionOpen === categoryType
      ? setAccordionOpen()
      : setAccordionOpen(categoryType);
  };

  const handleCheck = props => {
    props && props.checked
      ? filterDispatch({ type: 'UPDATE_CRITERIA', payload: props.value })
      : filterDispatch({ type: 'REMOVE_CRITERIA', payload: props.value });

    const paramsHandler = props.checked ? 'add' : 'remove';
    updateParamsSilently(
      props.value.category,
      props.value.option,
      paramsHandler,
      'testimonials',
      true
    );
  };

  const getFilterLabel = type => {
    switch (type) {
      case 'switchedFrom':
        return 'Shops Who Switched From';

      default:
        return decamelize(type);
    }
  };

  useEffect(() => {
    const params = getParams(['shopType', 'geolocation', 'switchedFrom']);
    stableDispatch({ type: 'LOAD_CRITERIA', payload: params });
  }, [stableDispatch]);

  return (
    <FilterStyles>
      {filterState?.filterOptions?.map(category => {
        const count = Object.keys(category.options).length;

        return (
          <Category
            key={category.type}
            open={accordionOpen === category.type}
            count={count}
          >
            <div
              className="label"
              onClick={() => handleAccordionClick(category.type)}
            >
              {getFilterLabel(category.type)}
              <Icon id="FiChevronUp" size={24} />
            </div>

            {(accordionOpen === category.type || isLargeScreen) && (
              <OptionsContainer>
                {Object.keys(category.options).map(option => (
                  <Fragment key={option}>
                    {option !== 'na' && (
                      <Checkbox
                        id={option}
                        label={optionNameOverride(option)}
                        value={{
                          category: category.type,
                          option,
                        }}
                        onClick={handleCheck}
                        isChecked={filterState.filterCriteria[
                          category.type
                        ].includes(option)}
                      />
                    )}
                  </Fragment>
                ))}
              </OptionsContainer>
            )}
          </Category>
        );
      })}
    </FilterStyles>
  );
};

export default Filter;
