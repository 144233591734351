import { m } from 'framer-motion';
import styled from 'styled-components';

import { colors } from '../../styles/atoms/colors';
import { font } from '../../styles/atoms/typography';
import shadow from '../../styles/atoms/shadows';

export const CheckboxStyle = styled(m.div)`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 16px;
  cursor: pointer;
  z-index: 10;

  label {
    display: flex;
    align-items: center;
    cursor: pointer;
    ${font('text', 'lg', '400')};
    text-transform: capitalize;
    margin: 0;
    pointer-events: none;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      width: ${props => (props.size === 'sm' ? '16px' : '20px')};
      height: ${props => (props.size === 'sm' ? '16px' : '20px')};
      border: 1px solid ${colors.gray[300]};
      border-radius: 3px;
      background-color: ${colors.base.white};
      cursor: pointer;
      transition: 0.2s;
    }

    &:after {
      content: '✔';
      position: absolute;
      left: ${props => (props.size === 'sm' ? '4px' : '6px')};
      font-size: ${props => (props.size === 'sm' ? '9.67px' : '11px')};
      color: ${colors.primary[500]};
      opacity: 0;
      transform: scale(0);
      transition: 0.2s;
    }
  }

  > input {
    opacity: 0;
    width: ${props => (props.size === 'sm' ? '16px' : '20px')};
    height: ${props => (props.size === 'sm' ? '16px' : '20px')};
    cursor: pointer;

    &:focus + label {
      &:before {
        border-color: ${colors.primary[300]};
        ${shadow('focusRing', colors.primary[50])};
      }
    }

    &:disabled + label {
      &:before {
        border-color: ${colors.gray[200]};
        background-color: ${colors.gray[100]};
      }
    }

    &:checked + label {
      &:before {
        border-color: ${colors.primary[500]};
        background-color: ${colors.primary[50]};
      }

      &:after {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  &:hover {
    label {
      &:before {
        border-color: ${colors.primary[500]};
        background-color: ${colors.primary[100]};
      }
    }
  }
`;
