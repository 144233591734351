import styled, { css } from 'styled-components';

import { atMinWidth } from '../../../styles/atoms/breakpoints';
import { colors } from '../../../styles/atoms/colors';
import { font } from '../../../styles/atoms/typography';

export const FilterStyles = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;
  flex: 1 0 auto;
  height: fit-content;
  padding: 32px 24px;
  background-color: ${colors.base.white};
  border-radius: 8px;

  ${atMinWidth.lg`
    max-width: 33.5%;
  `}
`;

export const Category = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  gap: ${props => (props.open ? '16px' : '0px')};
  width: 100%;

  ${atMinWidth.md`
    gap: 16px;
  `}

  .label {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    ${font('text', 'lg', '700')};
    text-transform: capitalize;
    padding: 16px;
    border-radius: 8px;
    cursor: pointer;
    transition: color 0.2s ease-in-out;

    svg {
      transform: ${props => (props.open ? 'rotate(0deg)' : 'rotate(180deg)')};
      transition: 0.2s;
    }

    ${atMinWidth.md`
      padding-left: 0px;

      svg {
        display: none;
      }
    `}

    ${props =>
      props.open &&
      css`
        background: ${colors.primary[50]};
        color: ${colors.primary[500]};

        ${atMinWidth.md`
          background: transparent;
          color: ${colors.gray[900]}
        `}
      `}
  }
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-flow: inherit;
  gap: inherit;
  width: 100%;
  overflow: hidden;
  height: 100%;

  ${atMinWidth.md`
    max-height: unset;
  `};
`;
