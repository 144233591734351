import React, { useEffect, useState } from 'react';

import { CheckboxStyle } from './Checkbox.styles';

const Checkbox = ({
  label,
  id,
  size = 'sm',
  value,
  onClick,
  isChecked = false,
}) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  const handleClick = () => {
    setChecked(!checked);
    const scopedConstant = !checked;
    onClick({ checked: scopedConstant, value });
  };

  return (
    <CheckboxStyle onClick={handleClick} size={size}>
      <input type="checkbox" id={id} checked={checked} name={id} value={id} />
      {label && <label htmlFor={id}>{label}</label>}
    </CheckboxStyle>
  );
};

export default Checkbox;
