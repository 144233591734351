import React, { createContext, useReducer } from 'react';
// import { captureException } from '@sentry/gatsby';

const initialState = {
  filterCriteria: {
    shopType: [],
    geolocation: [],
    switchedFrom: [],
  },
  filterOptions: [],
};

const reducer = (state, action) => {
  const { type, payload = { category: '', option: '' } } = action;
  const { category, option } = payload;

  switch (type) {
    case 'UPDATE_CRITERIA':
      return {
        ...state,
        filterCriteria: {
          ...state.filterCriteria,
          [category]: [...state.filterCriteria[category], option],
        },
      };
    case 'REMOVE_CRITERIA': {
      const index = state.filterCriteria[category].indexOf(option);
      const newCriteria = [
        ...state.filterCriteria[category].slice(0, index),
        ...state.filterCriteria[category].slice(index + 1),
      ];
      return {
        ...state,
        filterCriteria: {
          ...state.filterCriteria,
          [category]: newCriteria,
        },
      };
    }
    case 'LOAD_CRITERIA':
      return {
        ...state,
        filterCriteria: {
          ...payload,
        },
      };
    case 'SET_OPTIONS':
      return { ...state, filterOptions: [...payload] };
    case 'CLEAR_FILTERS':
      return { ...initialState };
    default:
      console.log('Review Reducer Failure', state, action);
  }
};

export const FilterContext = createContext({
  state: initialState,
  dispatch: null,
});

export const FilterProvider = ({ children }) => {
  const [filterState, filterDispatch] = useReducer(reducer, initialState);

  return (
    <FilterContext.Provider value={{ filterState, filterDispatch }}>
      {children}
    </FilterContext.Provider>
  );
};
